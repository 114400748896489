import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { PublicidadComponent } from './publicidad/publicidad.component';
import { SwiperModule } from 'swiper/angular';
import { MaterialModule } from '../material.module';
@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    PublicidadComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    MaterialModule

  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    PublicidadComponent

  ]
})
export class SharedModule { }
