import { Injectable, OnDestroy } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import * as eventos from '../../config/eventos-socket';


@Injectable({
  providedIn: 'root'
})
export class SocketsService {

  constructor(
    private socket: Socket,
  ) {


  }
  public socketEstado = true;
  verificarConexion() {
    console.log('incio socjkets')
    this.socket.on('connect', () => {
      console.log('conectado');


      if (!this.socketEstado) {
        this.configurarUsuario();

        this.socketEstado = true;
      }
      /*  this.route.navigate(['/']); */
    });

    this.socket.on('disconnect', () => {
      console.log('desconectado del servidor');
      this.socketEstado = false;
      /*      this.route.navigate(['/fuera_servicio']); */
    });

  }

  emitir(evento: string, payload?: any, callback?: (res: any) => void) {

    this.socket.emit(evento, payload, callback);
    console.log('emitiendo -> ', evento);
  }

  escuchar(evento: string) {
    return this.socket.fromEvent(evento);
  }

  configurarUsuario() {
    const token = localStorage.getItem('token');
    this.emitir(eventos.CONFIGURAR_USUARIO, token);

  }

}
