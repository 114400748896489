import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoadingScreenService } from "../services/loading-screen/loading-screen.service";
import { finalize } from "rxjs/operators";
import {
    WS_CONFIRMAR_PAGO_RITA,
    MB_REGISTRAR,
    MB_LEER,
    COMPRA_NORMAL,
    WS_MSJ_AVERIA,
    WS_REGISTRO_PEDIDO,
    WS_ENVIAR_VOUCHER,
    WS_CONFIRMAR_JUGO_PREAPARADO,
    WS_PEDIDO_DETALLE,
    WS_REGISTRAR_ESTADO_PEDIDO,
    WS_MONITOREO,
    WS_REGISTRAR_CUPON_PEDIDO,
    WS_VALIDAR_CUPON,
    MB_OPEN,
    WS_LOG_REGISTRAR
} from '../config/rutas';
import { environment } from 'src/environments/environment';


@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {

    activeRequests: number = 0;

    /**
     * URLs for which the loading screen should not be enabled
     */
    skippUrls = [
        `${environment.URL_SERVICIOS}/${WS_CONFIRMAR_PAGO_RITA}`,
        `${environment.URL_SERVICIOS}/${WS_MSJ_AVERIA}`,
        `${environment.URL_MODBUS}/${MB_REGISTRAR}`,
        `${environment.URL_MODBUS}/${MB_LEER}`,
        `${environment.URL_POS}/${COMPRA_NORMAL}`,
        `${environment.URL_SERVICIOS}/${WS_REGISTRO_PEDIDO}`,
        `${environment.URL_SERVICIOS}/${WS_ENVIAR_VOUCHER}`,
        `${environment.URL_SERVICIOS}/${WS_CONFIRMAR_JUGO_PREAPARADO}`,
        `${environment.URL_SERVICIOS}/${WS_PEDIDO_DETALLE}`,
        `${environment.URL_SERVICIOS}/${WS_REGISTRAR_ESTADO_PEDIDO}`,
        `${environment.URL_SERVICIOS}/${WS_MONITOREO}`,
        `${environment.URL_SERVICIOS}/${WS_REGISTRAR_CUPON_PEDIDO}`,
        `${environment.URL_SERVICIOS}/${WS_VALIDAR_CUPON}`,
        `${environment.URL_MODBUS}/${MB_OPEN}`,
        `${environment.URL_MODBUS}/${WS_LOG_REGISTRAR}`,
        

        
    ];

    constructor(private loadingScreenService: LoadingScreenService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let displayLoadingScreen = true;
        for (const skippUrl of this.skippUrls) {
            if (new RegExp(skippUrl).test(request.url)) {
                displayLoadingScreen = false;
                break;
            }
        }

        if (displayLoadingScreen) {
            if (this.activeRequests === 0) {
                this.loadingScreenService.startLoading();
            }
            this.activeRequests++;

            return next.handle(request).pipe(
                finalize(() => {
                    this.activeRequests--;
                    if (this.activeRequests === 0) {
                        this.loadingScreenService.stopLoading();
                    }
                })
            )
        } else {
            return next.handle(request);
        }
    };
}
