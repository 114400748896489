import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { OnboardComponent } from './onboard/onboard.component';
import { LoginGuard } from './services/guards/login.guard';
import { OlvidoClaveComponent } from './login/olvido-clave.component';
import { ConfirmacionComponent } from './login/confirmacion.component';
import { FueraServicioComponent } from './fueraServicio/fuera-servicio.component';
import { RitaComponent } from './rita/rita.component';


const appRoutes: Routes = [
    { path: 'onboard', component: OnboardComponent },
    { path: 'login', component: LoginComponent },
    { path: 'fuera_servicio', component: FueraServicioComponent },
    { path: 'restablecer', component: OlvidoClaveComponent },
    { path: 'mensaje', component: ConfirmacionComponent },
    {
        path: '',
        component: RitaComponent,
        canActivate: [LoginGuard],
        loadChildren: './rita/rita.module#RitaModule'
    },
    { path: '**', redirectTo: '/onboard', pathMatch: 'full' },
];


export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash: true });
