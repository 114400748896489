import { Injectable } from '@angular/core';
import { MetodosService } from '../metodos/metodos.service';
import { HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../usuario/usuario.service';
import {

  WS_AUTENTICAR_RITA_PUBLICIDAD
} from '../../config/rutas';
import { Mod } from '../../models/mod';
import { Pedido } from '../../interfaces/pedido.interface';
import { StockError } from '../../interfaces/stock-error.interface';
import { CuponBody } from '../../interfaces/cupon.interface';
import {

  WS_LISTAR_PUBLICIDAD
} from '../../config/rutas';
import { DATA } from 'src/assets/DATA';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicidadService {

  public modbuss: Mod = null;
  public pedido: Pedido = null;
  public stockError: StockError = null;
  cupon: CuponBody = null;
  public backHeader = false;
  constructor(
    private user: UsuarioService,
    private metodos: MetodosService,

  ) { }

  /* desactivado */
  getListaPublicidad(tipoPublicidad) {

    const headers = new HttpHeaders({
      'Authorization': ''
    });

    let body = {
      "tipoPublicidad": tipoPublicidad
    };

    return this.metodos.posNode(WS_LISTAR_PUBLICIDAD, body, headers);

  }

  getListaPublicidadx(tipoPublicidad: any) {

    const id = +tipoPublicidad;
    /* se obtiene un copia del array original */
    const data = { ...DATA };
    /* se obtiene la rita actual */
    const id_rita = +JSON.parse(localStorage.getItem('rita')).rita_id;
    /* se filtra todos los registro por el id_publicdad y id_rita  */
    const filtro_publicidad_rita = data.payload.filter(r => (r.rita_id === id_rita) && (r.tipoPublicidad === id));
    /* luego a cada imagen se concatena con en la path  base del proyecto a la imagen */
    const pub_imagenes = filtro_publicidad_rita.map(r => { return { ...r, publicidad_url: 'assets/pub/'.concat(r.publicidad_url) } });
    /* se reemplaza el apyload con ls datos filtrados */
    data.payload = pub_imagenes;
    console.log({ data, id_rita, filtro_publicidad_rita, pub_imagenes })
    /* se retorna en observavle la data filtrada */
    return of(data);


  }


  validarLoginRita(rita_id, rita_codigo, rita_token) {

    const headers = new HttpHeaders({
      'Authorization': ''
    });

    let body = {
      "rita_id": rita_id,
      "rita_codigo": rita_codigo,
      "token": rita_token
    };

    console.log("body", body);

    return this.metodos.posNode(WS_AUTENTICAR_RITA_PUBLICIDAD, body, headers);

  }

}
