import { Injectable } from '@angular/core';
import { interval, Subscription, timer } from 'rxjs';
import { SocketPedidoService } from '../web-sockets/socket-pedido.service';
import { filter, map, tap } from 'rxjs/operators';
import { UsuarioService } from '../usuario/usuario.service';
import { ModbusService } from '../modbus/modbus.service';
import { EstadoDesinfeccionLejia, EstadoDesinfeccionAgua } from 'src/app/enum/Desinfeccion.enum';
import { MetodosService } from '../metodos/metodos.service';
import { StockError } from '../../interfaces/stock-error.interface';
import { VariablesServices } from '../../config/variables';
import { Router } from '@angular/router';
import { PedidoService } from '../pedido/pedido.service';
import { HttpHeaders } from '@angular/common/http';
import { WS_DESINFECCION_INICIO, WS_DESINFECCION_FIN } from '../../config/rutas';

@Injectable({
  providedIn: 'root'
})
export class DesinfeccionService {


  horaInit = 13;
  timer = 1000 * 60 * 5; // cada 5 minutos cheuqea el estado
  timerDesinfeccion = 1000 * 60 * 4;  // cada  4 minutos chequea si la maquina termino de lavarse
  address = 38;
  subsDesinfeccionLejia: Subscription = new Subscription();
  subsDesinfeccionAgua: Subscription = new Subscription();
  subsDesinfeccionLejiaMod: Subscription = new Subscription();
  subsDesinfeccionAguaMod: Subscription = new Subscription();
  obsDefecto = new Subscription();
  notifiAgua: Subscription = new Subscription();
  notifiLejia: Subscription = new Subscription();


  constructor(private rita: SocketPedidoService,
    private usuario: UsuarioService,
    private mod: ModbusService,
    private ms: MetodosService,
    private env: VariablesServices,
    private router: Router,
    private wsPedido: PedidoService,
    private metodos: MetodosService,
    private user: UsuarioService,


  ) {


    /* cada dia a las 13 hora, los parametros  de actualizaran a su valor incial, es un reseteo, 
    asi nos aseguramos que el lavado siempre se dara en su hora dada
     */
    const time = localStorage.getItem('horaInit');
    if (time) {
      this.horaInit = +time;
    } else {
      localStorage.setItem('horaInit', this.horaInit.toString());
      const timeLocal = localStorage.getItem('horaInit');
      this.horaInit = +timeLocal;
    }

    let hoy = new Date();
    hoy.setHours(this.horaInit);
    hoy.setMinutes(0);
    hoy.setSeconds(0);
    this.reiniciarValoresDefecto(hoy);



  }



  enviarHoraDesinfeccioLejia() {


    const horaLavado = +JSON.parse(localStorage.getItem('entorno')).MODBUS_ADDRESS_HORA_LEJIA;


    console.log({ horaLavado })


    if (horaLavado) {
      this.subsDesinfeccionLejia = interval(this.timer).pipe(
        filter(() => {
          const hourActual = new Date().getHours();
          const diaActual = new Date().getDate();
          const diaLavado = localStorage.getItem('diaLavadoLejia');
          let dia = diaActual;
          if (diaLavado) {
            /* se podria validar que los dias deben ser consecutivos  o iguales y si sno lo son  setear dia  actual a la fcecha de lavado */
            dia = +diaLavado;
          }
          return (hourActual === horaLavado) && (diaActual === dia)
        })
      ).subscribe(() => {
        const token = this.usuario.estaLogueado();
        if (this.rita.ritaOcupada === false && token === true) {
          const estadoLimpieza = this.obtenerEstadoSessionLejia();
          console.log(estadoLimpieza)
          if (estadoLimpieza === EstadoDesinfeccionLejia.ANTES_LAVADO_LEJIA) {
            console.log('si se puede desinfectar');
            this.router.navigate(['/desinfeccion'])
            this.subsDesinfeccionLejia.unsubscribe();
            const tipo_desinfeccion = 1;
            const rita_id = JSON.parse(localStorage.getItem('rita')).rita_id;
            this.registrarHoraInicio({ tipo_desinfeccion, rita_id }).subscribe((res: any) => {
              if (res.estado) {
                this.registrarDesinfeccionLejiaMod(res.payload.hd_id);
                localStorage.setItem('historiao_id_lejia', res.payload.hd_id)
              }
            });
          } else {
            /*      console.log('la maquina esta desfincetada con lejia.') */
          }
        } else {
          /*  console.log('rita ocupada'); */
        }
      }, () => { }, () => {
        /*      console.log('completado perimer interval'); */
      });
    } else {
  /*     this.router.navigate(['/login']); */
    }

  }
  enviarHoraDesinfeccioAgua() {
    const horaLavado = +JSON.parse(localStorage.getItem('entorno')).MODBUS_ADDRESS_HORA_AGUA;
    console.log({ horaLavado })
    if (horaLavado) {
      this.subsDesinfeccionAgua = interval(this.timer).pipe(
        filter(() => {

          const hourActual = new Date().getHours();
          const diaActual = new Date().getDate();
          const diaLavado = localStorage.getItem('diaLavadoAgua');
          let dia = diaActual;
          if (diaLavado) {
            dia = +diaLavado;
          }
          return (hourActual === horaLavado) && (diaActual === dia)
        })
      ).subscribe(() => {
        console.log('aguaa')
        const token = this.usuario.estaLogueado();
        console.log('validar si se pueded desinfectar con agua')
        if (this.rita.ritaOcupada === false && token === true) {
          const estadoLimpieAgua = this.obtenerEstadoSessionAgua();
          if (
            estadoLimpieAgua === EstadoDesinfeccionAgua.ANTES_LAVADO_AGUA
          ) {
            console.log('si se puede desinfectar agua');
            this.router.navigate(['/desinfeccion'])
            this.subsDesinfeccionAgua.unsubscribe();
            const tipo_desinfeccion = 2;
            const rita_id = JSON.parse(localStorage.getItem('rita')).rita_id;
            this.registrarHoraInicio({ tipo_desinfeccion, rita_id }).subscribe((res: any) => {
              console.log(res)
              if (res.estado) {
                console.log('registro de inicio lavado agua ok!')
                this.registrarDesinfeccionAguaMod(res.payload.hd_id);
                localStorage.setItem('historiao_id_agua', res.payload.hd_id);
              }
            });
          } else {
            /*    console.log('la maquina esta desfincetada con agua.') */
          }
        } else {
          /*       console.log('rita ocupada para  desf agua'); */
        }
      }, () => { }, () => {
        /*   console.log('completado perimer interval'); */
      });
    } else {
      /*     this.router.navigate(['/login']); */
    }


  }
  registrarDesinfeccionLejiaMod(hd_id: number) {
    this.guardarSession(EstadoDesinfeccionLejia.DURANTE_LAVADO_LEJIA);
    this.router.navigate(['/desinfeccion'])
    const addres = JSON.parse(localStorage.getItem('entorno')).MODBUS_ADDRESS_DEFINECCION;
    const value = JSON.parse(localStorage.getItem('entorno')).MODBUS_ADDRESS_LEJIA_VALUE;
    console.log(addres, value);
    this.mod.modbusRegistrarTipoDesinfeccion(addres, value).
      subscribe((res: any) => {
        if (res.estado) {
          this.subsDesinfeccionLejiaMod = interval(this.timerDesinfeccion)
            .subscribe(() => {
              /*     console.log('en proceso de lavado ... '); */
              this.mod.modbusLeerEstadoDesfineccion(34)
                .pipe(
                  map((obj: any) => obj.payload.registers[0]),
                  filter((valor: number) => valor === 0 || valor === 1)
                )
                .subscribe((resultado) => {
                  console.log({ resultado })
                  this.subsDesinfeccionLejiaMod.unsubscribe();
                  if (resultado === 1) {
                    console.log('se limpio con lejia sucess');
                    this.guardarSession(EstadoDesinfeccionLejia.DESPUES_LAVADO_LEJIA);
                    this.agregarDiaLavadoLejia();
                    this.enviarHoraDesinfeccioLejia();
                    const body = { hd_id };
                    this.router.navigate(['/']);
                    this.registrarHoraFin(body).subscribe(() => {
                    });
                  } else if (resultado === 0) {
                    console.log('error, enviar notifiacion');
                    this.enviarNotificacion('Hubo un error al momento de  lavar la rita con lejia');
                  }
                });
            });
        } else {
          this.enviarNotificacion('Hubo un error al momento de registrar el tipo de lavado de rita con lejia');
        }
      }, () => {
        console.log('error al registrar lavado , enviar notifiacion');
        this.enviarNotificacion('Hubo un error de conexion al momento de registrar el tipo de lavado de rita con lejia');
      });
  }


  registrarDesinfeccionAguaMod(hd_id: number) {
    this.guardarSessionAgua(EstadoDesinfeccionAgua.DURANTE_LAVADO_AGUA);
    this.router.navigate(['/desinfeccion'])
    const addres = JSON.parse(localStorage.getItem('entorno')).MODBUS_ADDRESS_DEFINECCION;
    const value = JSON.parse(localStorage.getItem('entorno')).MODBUS_ADDRESS_AGUA_VALUE;
    console.log(addres, value);
    this.mod.modbusRegistrarTipoDesinfeccion(addres, value).
      subscribe((res: any) => {
        if (res.estado) {
          this.subsDesinfeccionAguaMod = interval(this.timerDesinfeccion)
            .subscribe(() => {
              console.log('en proceso de lavado agua ... ');
              this.mod.modbusLeerEstadoDesfineccion(34)
                .pipe(
                  map((obj: any) => obj.payload.registers[0]),
                  filter((valor: number) => valor === 0 || valor === 1)
                )
                .subscribe((resultado) => {
                  console.log({ resultado })
                  this.subsDesinfeccionAguaMod.unsubscribe();
                  if (resultado === 1) {
                    console.log('se limpio con agua sucess');
                    this.guardarSessionAgua(EstadoDesinfeccionAgua.DESPUES_LAVADO_AGUA);
                    this.agregarDiaLavadoAgua();
                    this.enviarHoraDesinfeccioAgua();
                    const body = { hd_id };
                    this.router.navigate(['/'])
                    this.registrarHoraFin(body).subscribe(() => {
                    });
                  } else if (resultado === 0) {
                    console.log('error, enviar notifiacion');
                    this.enviarNotificacion('Hubo un error al momento de obetener el estado del mosbuss al lavar con agua -- estado =  0');
                  }
                });
            });
        } else {
          this.enviarNotificacion('Hubo un error al momento de registrar el tipo de lavado de rita con agua');
        }
      }, () => {
        console.log('error al registrar lavado , enviar notifiacion');
        this.enviarNotificacion('Hubo un error de conexion al momento de registrar el tipo de lavado de rita con agua');
      });
  }

  /* se obtiene el estado del lavado de lejia , si qque no encuentra , setea valores por defecto y retrna el estado actual */
  obtenerEstadoSessionLejia() {
    let sessionEstadoLimpieza = localStorage.getItem('estadoLimpiezaLejia');
    let estado = 0;
    if (sessionEstadoLimpieza) {
      estado = +sessionEstadoLimpieza;
    } else {
      estado = EstadoDesinfeccionLejia.ANTES_LAVADO_LEJIA;
      localStorage.setItem('estadoLimpiezaLejia', estado.toString());
    }
    return estado;
  }
  /* se obtiene el estado del lavado de agua , si qque no encuentra , setea valores por defecto y retrna el estado actual */
  obtenerEstadoSessionAgua() {
    let sessionEstadoLimpieza = localStorage.getItem('estadoLimpiezaAgua');
    let estado = 0;
    if (sessionEstadoLimpieza) {
      estado = +sessionEstadoLimpieza;
    } else {
      estado = EstadoDesinfeccionAgua.ANTES_LAVADO_AGUA;
      localStorage.setItem('estadoLimpiezaAgua', estado.toString());
    }
    return estado;
  }
  guardarSession(enumEstado: EstadoDesinfeccionLejia) {
    localStorage.setItem('estadoLimpiezaLejia', enumEstado.toString());
  }
  guardarSessionAgua(enumEstado: EstadoDesinfeccionAgua) {
    localStorage.setItem('estadoLimpiezaAgua', enumEstado.toString());
  }


  iniciarDesinfeccion() {
    const estadoLEjia = this.obtenerEstadoSessionLejia();
    console.log('estado desinfeccion lejia -->' + estadoLEjia);
    switch (estadoLEjia) {
      case EstadoDesinfeccionLejia.ANTES_LAVADO_LEJIA:
        this.enviarHoraDesinfeccioLejia();
        break;
      case EstadoDesinfeccionLejia.DESPUES_LAVADO_LEJIA:
        this.enviarHoraDesinfeccioLejia();

        break;
      case EstadoDesinfeccionLejia.DURANTE_LAVADO_LEJIA:
        console.log('REESTABLECINEDO LIMPIEZA AGUA');
        const id = localStorage.getItem('historiao_id_lejia');
        this.registrarDesinfeccionLejiaMod(+id);
        break;
    }
    const estadoAgua = this.obtenerEstadoSessionAgua();
    console.log('estado desinfeccion agua -->' + estadoAgua);
    switch (estadoAgua) {
      case EstadoDesinfeccionAgua.ANTES_LAVADO_AGUA:
        this.enviarHoraDesinfeccioAgua();
        break;
      case EstadoDesinfeccionAgua.DESPUES_LAVADO_AGUA:
        this.enviarHoraDesinfeccioAgua();

        break;
      case EstadoDesinfeccionAgua.DURANTE_LAVADO_AGUA:
        const id = localStorage.getItem('historiao_id_agua');
        console.log('REESTABLECINEDO LIMPIEZA AGUA');
        this.registrarDesinfeccionAguaMod(+id);
        break;
    }


  }
  deshabilitarDesinfeccion() {
    this.subsDesinfeccionLejia.unsubscribe();
    this.subsDesinfeccionLejiaMod.unsubscribe();
    this.subsDesinfeccionAgua.unsubscribe();
    this.subsDesinfeccionAguaMod.unsubscribe();
    this.notifiAgua.unsubscribe();
    this.notifiLejia.unsubscribe();
  }
  enviarNotificacion(mensaje: string) {
    this.ms.flagErrorModbus = true;
    localStorage.setItem('flagErrorModbus', this.ms.flagErrorModbus + '');
    this.ms.address_confirmacion = 34;
    this.ms.address_error = 36;
    const tlfn = this.env.CELULAR_SOPORTE_TECNICO;
    console.log(tlfn)
    const errorStock: StockError = {
      estado: false,
      mensaje,
      motivo: '',
      status: 1,
      celular: tlfn
    };
    this.wsPedido.stockError = errorStock;
    this.router.navigate(['/fuera_servicio']);

  }


  registrarHoraInicio(body: any) {

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_DESINFECCION_INICIO, body, headers);

  }
  registrarHoraFin(body: any) {

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });
    return this.metodos.postQuery(WS_DESINFECCION_FIN, body, headers);

  }


  /* reinciia los valores cuando carga la app y  todos los dias  a la 7 pm reinicia  los valores por defecto */

  reiniciarValoresDefecto(hoy: Date) {
    console.log('valiores se reinciaran a las ');
    console.log(hoy);
    /* en caso slos estado seab nulos, se reinician al valor por defecto - init*/
    this.obtenerEstadoSessionLejia();
    this.obtenerEstadoSessionAgua();

    this.obsDefecto = timer(hoy)
      .subscribe((r) => {
        console.log({ r })
        this.guardarSession(EstadoDesinfeccionLejia.ANTES_LAVADO_LEJIA);
        this.guardarSessionAgua(EstadoDesinfeccionAgua.ANTES_LAVADO_AGUA);
        const manana = new Date(Date.now() + (1 * 86400000));
        manana.setDate(manana.getDate());
        manana.setHours(this.horaInit);
        manana.setMinutes(0);
        manana.setSeconds(0);
        /*  despues cada dia se iram actualizando a la 1 pm*/
        this.reiniciarValoresDefecto(manana);
      });
  }
  agregarDiaLavadoAgua() {
    const calc_date = new Date(Date.now() + (1 * 86400000));
    localStorage.setItem('diaLavadoAgua', calc_date.getDate().toString());
  }
  agregarDiaLavadoLejia() {
    const calc_date = new Date(Date.now() + (1 * 86400000));
    localStorage.setItem('diaLavadoLejia', calc_date.toString());
  }
}