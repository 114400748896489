import { Component, Input, OnInit } from '@angular/core';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper/core';
import { PublicidadService } from '../../services/publicidad/publicidad.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval, Subscription } from 'rxjs';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])



@Component({
  selector: 'app-publicidad',
  // template: ``,
  templateUrl: './publicidad.component.html',
  styleUrls: ['./publicidad.component.css']
})
export class PublicidadComponent implements OnInit {

  //Objeto donde vendran las imagenes/videos
  public arraySlides: any[] = [];
  public control: any;
  public waka: any = localStorage.getItem('pub_index_actual');
  @Input('tipoPublicidad') tipoPublicidad: any;
  subscription: Subscription;

  onSwiper(swiper) {

    console.log(swiper);

    if( typeof this.control != 'undefined' ){

      let index_actual = this.control.activeIndex;
      let elemento_actual = this.control.slides[index_actual];
      let videoElementoActual = elemento_actual.getElementsByTagName("video");
      
      console.log("index_actual",index_actual);

      if( videoElementoActual.length > 0 ){
        videoElementoActual[0].play();
      }
      else{
        document.querySelectorAll('video').forEach(vid => {
          vid.pause();
          vid.muted;
          vid.currentTime = 0;
        });
      }

    }
    else{
      console.log("undefined")
    }

  }
  onSlideChange(change) {

    if( typeof this.control != 'undefined' ){

      let index_actual = this.control.activeIndex;
      let elemento_actual = this.control.slides[index_actual];
      let videoElementoActual = elemento_actual.getElementsByTagName("video");
      
      console.log("index_actual",index_actual);

      if( videoElementoActual.length > 0 ){
        videoElementoActual[0].play();
      }
      else{
        document.querySelectorAll('video').forEach(vid => {
          vid.pause();
          vid.muted;
          vid.currentTime = 0;
        });
      }

      this.validarSiguienteSlide(index_actual);

    }
  }

  constructor(
    private ws_pedido_pub: PublicidadService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(){
    this.getPublicidad();

    const source = interval(10000);
    this.subscription = source.subscribe(val => this.reproducirVideos());
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  //Falta buscar mejor manera de reproducir el video que se muestra
  //Ahora se reproducen todos
  reproducirVideos()
  {
    // console.log("Reproducir videos")
    // document.querySelectorAll('video').forEach(vid => {
    //   vid.currentTime = 0;
    //   vid.play();
    // });
  }

  getPublicidad()
  {

    this.ws_pedido_pub.getListaPublicidad(this.tipoPublicidad).subscribe(
      (res: any) => {

        console.log("INICIO Publicidad");

        let index_actual = +localStorage.getItem("pub_index_actual");

        if( index_actual == 0 ){
          index_actual = 0;
        }
        else{
          index_actual = index_actual;
        }

        console.log("res",res);
        this.arraySlides = res.payload;
        this.validarSiguienteSlide(index_actual);

        console.log("FIN Publicidad");

        this.onSwiper;

      },
      err => {
        this.snackBar.open('El servidor no responde', 'Cerrar', {
          // duration: this.env.DURATIONSNACK
          duration: 5
        });
        console.log("err",err);
      }
    );

  }

  validarSiguienteSlide(index_actual)
  {

    console.log("validar slide");

    let cantidad_slides = this.arraySlides.length;
    let siguiente_slide = +index_actual + +1;

    console.log("cantidad_slides",cantidad_slides);
    console.log("siguiente_slide",siguiente_slide);

    //Cargamos el siguiente slide respecto al pub_index_actual almacenado en el localStorage
    // this.waka = localStorage.getItem('pub_index_actual');

    if( cantidad_slides > 0 && cantidad_slides > siguiente_slide ){
      index_actual = siguiente_slide;
      console.log("COND 1");
    }
    else{
      index_actual = 0;
      console.log("COND 2");
    }
    console.log("index_actual final",index_actual);
    localStorage.setItem('pub_index_actual', index_actual);

  }

}
