import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MensajeInterface } from '../../components/mensaje-interface';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VariablesEntorno } from '../../models/variablesEntorno';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VariablesServices } from '../../config/variables';
@Injectable({
  providedIn: 'root'
})
export class MetodosService {
  public flagErrorModbus: boolean = false;
  public flaghedaer: boolean = true;
  public pantallaCarga: Subscription;
  public contador: number = 0;
  public flagBotonMenu: boolean = true;

  public mensaje: MensajeInterface = {

    mensajePrincipal: '¡Operación denegada!',
    mensajeSecundario: 'Vuelva a intentarlo',

  };



  public estaConectado = false;
  public environment: VariablesEntorno;
  public codigoLecturaModbus: number;
  public sumatotalPrecio = 0;
  public address_confirmacion: number = 34;
  public address_error: number = 36;
  public fromClient = 2; // de la amquina 



  constructor(private http: HttpClient, private snack: MatSnackBar, private vs: VariablesServices) {

  }

  getQuery(query: string, headers: HttpHeaders) {
    const url = `${environment.URL_SERVICIOS}/${query}`;
    return this.http.get(url, { headers });

  }
  postQuery(query: string, body: any, headers: HttpHeaders) {

    const url = `${environment.URL_SERVICIOS}/${query}`;
    return this.http.post(url, body, { headers });

  }

  postQueryJon(query: string, body: string, headers: HttpHeaders) {

    const url = `${environment.URL_SERVICIOS}/${query}`;
    return this.http.post(url, JSON.parse(body), { headers });

  }

  modbus(query: string, body: object, headers: HttpHeaders) {

    const url = `${environment.URL_MODBUS}/${query}`;
    return this.http.post(url, body, { headers });

  }

  posJava(query: string, body: string, headers: HttpHeaders) {

    const url = `${environment.URL_POS}/${query}`;
    return this.http.post(url, JSON.parse(body), { headers });

  }

  public mensajeSnack(mensaje: string) {
    this.snack.open(mensaje, 'Cerrar', { duration: this.vs.DURATIONSNACK, panelClass: 'mycsssnackbartest' });

  }
  posNode(query: string, body: any, headers: HttpHeaders) {
    const url = `${environment.URL_NODE}/${query}`;
    return this.http.post(url, body, { headers });
  }

  mostrarHeader() {
    this.flaghedaer = true;
  }
  ocultarHeader() {
    this.flaghedaer = false;
  }
}
