export const DATA =
{
    estado: true,
    status: 200,
    mensaje: "OK",
    payload: [
        {
            "publicidad_id": 1,
            "rita_id": 1,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "lEGnoSqMfSEMF0ZovDHDWuoA9g6eXNGISpJQMOCp.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 2,
            "rita_id": 1,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AW8jRB8j8nAIMacMlctOryPMqTjYVhBirsB2kFaj.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 3,
            "rita_id": 9,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 11,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 10,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 8,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 1,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 2,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 3,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 5,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 4,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 6,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 3,
            "rita_id": 7,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "ToXnigM4Z694Lsp90Nr3KHDqlyBUlSf1il3W6vgQ.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 9,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 11,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 10,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 8,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 1,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 2,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 3,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 5,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 4,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 6,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 4,
            "rita_id": 7,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "1CLRAlxcuEVQw0tgoGJPYBQCckvSzFQaTBxFd6fN.png",
            "tipoPublicidad": 2
        },
        {
            "publicidad_id": 5,
            "rita_id": 9,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 11,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 10,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 8,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 1,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 2,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 3,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 5,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 4,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 6,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 5,
            "rita_id": 7,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "Rq5E9KNrCzCzda2mU1zBtSsIS3B5UlVO0pFPChBI.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 9,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 11,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 10,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 8,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 1,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 2,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 3,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 5,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 4,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 6,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 6,
            "rita_id": 7,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "AhzbpjNgEc8qx1rfa3geXUDJDxcoJ6kQ4G5Wzy9R.png",
            "tipoPublicidad": 3
        },
        {
            "publicidad_id": 7,
            "rita_id": 1,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 4,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 5,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 6,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 9,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 7,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 11,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 10,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 2,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 8,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 7,
            "rita_id": 3,
            "publicidad_tipo": 1,
            "publicidad_orden": 1,
            "publicidad_url": "01TJcD397hFGjyi0BCFH04diwL8qR0f5KaVRLmvD.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 5,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 10,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 6,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 9,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 7,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 11,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 2,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 8,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        },
        {
            "publicidad_id": 8,
            "rita_id": 3,
            "publicidad_tipo": 1,
            "publicidad_orden": 2,
            "publicidad_url": "Wv1kiwZgSsYuOPlfRW75HzxwwtmbldfmSFbVdRle.png",
            "tipoPublicidad": 1
        }
    ]
}
