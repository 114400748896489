export class ResultadoWS {

    constructor(
        public estado?:boolean,
        public mensaje?: string,
        public status?: number,
        public payload?:{
            access_token:'',
            token_type:'',
            expires_in:''
        }
        
    ) { }

}