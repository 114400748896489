import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsuarioService } from '../services/usuario/usuario.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { VariablesServices } from '../config/variables';

@Component({
  selector: 'app-olvido-clave',
  templateUrl: './olvido-clave.component.html',
  styles: []
})


export class OlvidoClaveComponent implements OnInit {

  formulario: FormGroup
  loading: boolean = false;
  constructor(
    private usuario: UsuarioService,
    private snackBar: MatSnackBar,
    private router: Router,
    private env: VariablesServices) { }
  private pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$";
  ngOnInit() {
    this.formulario = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.pattern(this.pattern)])
    });


  }
  body: object = {
    email: ''
  };


  resetPassword() {

    if (this.validar()) {
      this.loading = true;
      let body = JSON.stringify(this.formulario.value);
      this.usuario.getRecuperarPassword(body)
        .subscribe((data: any) => {
          this.snackBar.open(data.mensaje, 'Cerrar', { duration: 10 })
          this.loading = false;

          if (data.estado) {
            this.router.navigate(['/mensaje']);
          }

        }, err => {

          this.loading = false;
          this.snackBar.open(err.mensaje, 'Cerrar', { duration: 10 })
        });

    }

  }

  validar() {

    if (this.formulario.controls['email'].valid) {
      return true;
    }
    else {
      this.snackBar.open('El email no es válido', 'cerrar', { duration: this.env.DURATIONSNACK });
      return false;
    }
  }


}
