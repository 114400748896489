import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { MetodosService } from '../metodos/metodos.service';
import { WS_LOG_REGISTRAR } from '../../config/rutas';
import { LogInterface } from '../../interfaces/log.interface';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private metodos: MetodosService, private user: UsuarioService) { }

  registrarLog(body: LogInterface) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_LOG_REGISTRAR, body, headers);

  }
}
