import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DevService {

  constructor() { }

  obtenerConfig() {
    const modbuss = (localStorage.getItem('modbuss') === 'true');
    const pos = (localStorage.getItem('pos') === 'true');
    return {
      modbuss,
      pos
    };
  }
}
