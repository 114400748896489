import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsuarioService } from '../services/usuario/usuario.service';
import { Observable, Subscription } from 'rxjs';
import { MetodosService } from '../services/metodos/metodos.service';
import { ModbusService } from '../services/modbus/modbus.service';
import { Router } from '@angular/router';
import { StockError } from '../interfaces/stock-error.interface';
import { PedidoService } from '../services/pedido/pedido.service';

@Component({
  selector: 'app-fuera-servicio',
  templateUrl: './fuera-servicio.component.html',
  styleUrls: ['./fuera-servicio.component.css']
})
export class FueraServicioComponent implements OnInit, OnDestroy {

  constructor(
    private usuario: UsuarioService,
    private ws: MetodosService,
    private mb: ModbusService,
    private pedido: PedidoService,
    private router: Router,

  ) { }
  public subs: Subscription
  public subsMod: Subscription
  public intervalo: any;
  public intervaloMod: any;
  public stockError: StockError = null;
  ngOnInit() {

    this.stockError = this.pedido.stockError;
    // consulta el error de la maquina y lo envia al ws
    const flagMod = localStorage.getItem('flagErrorModbus');
    if (flagMod) {
      this.ws.flagErrorModbus = flagMod === 'true' ? true : false;
    } else {
      localStorage.setItem('flagErrorModbus', this.ws.flagErrorModbus + '');
    }       
    if (this.ws.flagErrorModbus) {
      this.mb.modbusLeer(this.ws.address_confirmacion).subscribe((res: any) => {
        if (res.estado) {
          if (res.payload.registers[0] !== 1) {
            const id = this.ws.address_error;
            this.mb.modbusLeer(id).subscribe((res: any) => {
              const errorCode = res.payload.registers[0];
              this.usuario.enviarMensajeAveria({ error_code: errorCode }).subscribe(() => {
                console.log(res);
              });
            });
          }
        }
      });
      //  consulta cada 5 minutos si la maquina ya  está ok!
      console.log('error del modbus');
      this.subsMod = this.validarErrorModbus().subscribe((res: boolean) => {
        console.log(res);
        if (res) {
          this.router.navigate(['/']);
          this.ws.flagErrorModbus = false;
          localStorage.setItem('flagErrorModbus', this.ws.flagErrorModbus + '');
        }
      });
    } else {
      this.usuario.enviarMensajeAveria()
        .subscribe((res: any) => {
          console.log('uno', res);
          if (!res.estado) {
            this.subs = this.enviarNotificacion().subscribe((res: any) => {
              console.log('uno', res);
            });
          }
        });
    }
  }

  ngOnDestroy() {

    if (this.subs != null) {
      this.subs.unsubscribe();
    }
    if (this.subsMod != null) {
      this.subsMod.unsubscribe();
    }
    this.pedido.stockError = null;
    clearInterval(this.intervalo);
  }


  validarErrorModbus(): Observable<boolean> {
    return new Observable(observer => {
      this.intervaloMod = setInterval(() => {
        this.mb.modbusLeer(this.ws.address_confirmacion).subscribe((res: any) => {
          if (res.estado) {
            if (res.payload.registers[0] === 1) {
              observer.next(true);
              observer.complete();
              clearInterval(this.intervaloMod);
            }
          }
        });

      }, 1000 * 60 * 5); // cada cinco minutos
    });
  }

  enviarNotificacion(body?: any) {
    return new Observable(observer => {
      this.intervalo = setInterval(() => {
        // this.ms.contador = 0;
        this.usuario.enviarMensajeAveria()
          .subscribe((res: any) => {
            console.log('mensaje ==> ', res);
            if (res.estado) {
              observer.next(true);
              clearInterval(this.intervalo);
              observer.complete();
            } else {
              observer.next(false);
            }
          });

      }, 60000 * 30);
    });
  }
  // 
  apagarDesinfeccion() {


  }
  encenderDesinfeccion() {

  }
  logout() {
    this.usuario.logout();
  }
}
