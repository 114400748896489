import { Injectable } from '@angular/core';
import { MetodosService } from '../metodos/metodos.service';
import { HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../usuario/usuario.service';
import {
  WS_REGISTRO_PEDIDO,
  WS_VALIDAR_PEDIDO_APP,
  WS_REGISTRAR_ESTADO_PEDIDO,
  WS_CONFIRMAR_JUGO_PREAPARADO
} from '../../config/rutas';
import { Mod } from '../../models/mod';
import { Pedido } from '../../interfaces/pedido.interface';
import { StockError } from '../../interfaces/stock-error.interface';
import { WS_ENVIAR_VOUCHER, WS_PEDIDO_DETALLE } from '../../config/rutas';
import { CuponBody } from '../../interfaces/cupon.interface';
import { JugoFuncional } from '../../interfaces/jugo-funcional.interface';
import {
  WS_GET_FRUTAS,
  WS_SELEC_FRUTA,
  WS_GET_TOPPINGS,
  WS_SELEC_TOPPING,
  WS_PEDIDO_RESUMEN,
  WS_LIMPIAR_PEDIDO,
  WS_CONFIRMAR_PAGO_RITA
} from '../../config/rutas';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  public modbuss: Mod = null;
  public pedido: Pedido = null;
  public stockError: StockError = null;
  cupon: CuponBody = null;
  public backHeader = false;
  jugoFuncional: JugoFuncional = null;
  constructor(
    private user: UsuarioService,
    private metodos: MetodosService,

  ) { }

  getListaFrutas() {

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_GET_FRUTAS, '', headers);

  }
  registrarFruta(body: string) {

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });
    console.log(body);
    return this.metodos.postQueryJon(WS_SELEC_FRUTA, body, headers);

  }
  getListaToppings() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_GET_TOPPINGS, '', headers);

  }
  registrartopping(body: string) {

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQueryJon(WS_SELEC_TOPPING, body, headers);

  }
  getResumenPedido() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_PEDIDO_RESUMEN, '', headers);
  }

  getLimpiarPedido() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_LIMPIAR_PEDIDO, '', headers);

  }

  registrarPedido(body: any) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_REGISTRO_PEDIDO, body, headers);
  }
  confirmarPagoRita(body: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQueryJon(WS_CONFIRMAR_PAGO_RITA, body, headers);
  }

  validarPedido(body: any) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_VALIDAR_PEDIDO_APP, body, headers);
  }
  registrarEstadoPedido(body: any) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_REGISTRAR_ESTADO_PEDIDO, body, headers);
  }
  confirmarJugoPreparado(body?: any) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_CONFIRMAR_JUGO_PREAPARADO, body, headers);
  }

  enviarVoucher(body: any) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_ENVIAR_VOUCHER, body, headers);

  }
  verPedido() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.postQuery(WS_PEDIDO_DETALLE, null, headers);

  }
}
