import { Injectable } from '@angular/core';
import { UsuarioService } from '../usuario/usuario.service';
import { MetodosService } from '../metodos/metodos.service';
import { HttpHeaders } from '@angular/common/http';
import { COMPRA_NORMAL, WS_ENVIAR_VOUCHER } from '../../config/rutas';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PosService {

  constructor(
    private user: UsuarioService,
    private metodos: MetodosService,

  ) { }

  compraNormal(body: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.user.token}`
    });

    return this.metodos.posJava(COMPRA_NORMAL, body, headers);
    /*     return of({ mensaje: "", estado: true, status: 200, payload: {} }); */

  }
}
