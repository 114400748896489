 
export const URL_MODBUS = 'http://localhost:3000';
export const URL_POS = 'http://localhost:8080';
export const URL_NODE = 'http://localhost:3001';

/* export const URL_SERVICIOS = 'https://ws-qa.rita.com.pe/api';
export const URL_SOCKET = 'https://socket-qa.rita.com.pe:5021';
 */
export const URL_SERVICIOS = 'https://ws.rita.com.pe/api';
// export const URL_SOCKET = 'https://node-rita.wydnex.com:5021'



export const URL_SOCKET = 'http://192.168.0.6:5000';
// export const URL_SERVICIOS = 'http://localhost:8001/api';