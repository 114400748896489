import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { MetodosService } from '../metodos/metodos.service';
import { Usuario } from '../../models/usuario';
import { Router } from '@angular/router';
import {
  WS_RESET_PASSWORD,
  WS_USUARIO, WS_LOGIN_RITA,
  WS_LOGOUT, WS_REFRESH,
  WS_AUTH_ME,
  WS_VARIABLE_ENTORNO,
  WS_MSJ_AVERIA,
  WS_MONITOREO
} from '../../config/rutas';
import { VariablesEntorno } from '../../models/variablesEntorno';
import { SocketsService } from '../web-sockets/sockets.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  tokenx: string;

  get token() {
    const token = localStorage.getItem('token');
    if (token) {
      return token;
    }
    return '';
  }
  public usuario: Usuario = new Usuario();
  tokenExpire: string;

  constructor(
    private metodos: MetodosService,
    private router: Router,
  ) {
    this.cargarStorage();
  }


  prueba() {
    const headers = new HttpHeaders({
      'Authorization': ''
    });
    return this.metodos.getQuery(`fruta`, headers)
      .pipe(map(lista => lista));

  }



  login(obj: Usuario, recordar: boolean = true) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (recordar) {
      localStorage.setItem('name', obj.name);
    } else {
      localStorage.removeItem('name');
    }

    let body = JSON.stringify(obj);
    return this.metodos.postQuery(WS_LOGIN_RITA, body, headers);

  }

  guardarStorage(token: string, expiresIn: string) {

    localStorage.setItem('token', token);
    localStorage.setItem('expires_in', expiresIn);
    this.tokenx = token;

  }


  getVariableEntorno() {

    const env: VariablesEntorno = {
      API_KEY: '',
      DURATIONSNACK: parseInt(localStorage.getItem('DURATIONSNACK')),
      INTERVALO_PETICIONES: parseInt(localStorage.getItem('INTERVALO_PETICIONES')),
      MOD_INTERVALO_PETICIONES: parseInt(localStorage.getItem('MOD_INTERVALO_PETICIONES')),
      MOD_NUMERO_MAX_PETICIONES: parseInt(localStorage.getItem('MOD_NUMERO_MAX_PETICIONES')),
      NUMERO_MAX_PETICIONES: parseInt(localStorage.getItem('NUMERO_MAX_PETICIONES')),
      TIEMPO_PANTALLA_CARGA: parseInt(localStorage.getItem('TIEMPO_PANTALLA_CARGA')),
      TOKEN_DIAS: parseInt(localStorage.getItem('TOKEN_DIAS')),
      CELULAR_SOPORTE_TECNICO: localStorage.getItem('CELULAR_SOPORTE_TECNICO'),
      MODBUS_ADDRESS_ERROR: +localStorage.getItem('MODBUS_ADDRESS_ERROR'),
      MODBUS_ADDRESS_ESTADO: +localStorage.getItem('MODBUS_ADDRESS_ESTADO'),

    }
    return env;

  }


  cargarStorage() {
    if (localStorage.getItem('token')) {
      this.tokenx = localStorage.getItem('token');
      this.tokenExpire = localStorage.getItem('expires_in');

    } else {
      this.tokenx = '';
      this.tokenExpire = '';

    }

  }
  estaLogueado() {
    return (this.token.length > 5) ? true : false;
  }

  logout() {

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });
    this.clearLocalStorahe();
    this.router.navigate(['/login']);
    this.metodos.postQuery(WS_LOGOUT, '', headers)
      .subscribe((res) => {
        console.log('deslogeado');
      });

  }
  clearLocalStorahe() {
    this.tokenx = '';
    this.tokenExpire = '';
    localStorage.removeItem('token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('rita');
  }

  renuevaToken() {

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    return this.metodos.postQuery(WS_REFRESH, '', headers);


  }

  getUsuario() {

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`
    });

    return this.metodos.postQuery(WS_AUTH_ME, '', headers);

  }
  getRecuperarPassword(body: string) {
    const headers = new HttpHeaders({
      'Authorization': ``
    });
    console.log(body);
    return this.metodos.postQueryJon(WS_RESET_PASSWORD, body, headers);
  }
  getDatosUsuario() {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    return this.metodos.postQuery(WS_USUARIO, '', headers);
  }

  getVariablesEntorno() {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    return this.metodos.postQuery(WS_VARIABLE_ENTORNO, '', headers);
  }


  enviarMensajeAveria(body?: any) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`
    });

    return this.metodos.postQuery(WS_MSJ_AVERIA, body, headers);
  }

  monitorear(body: any) {

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`
    });

    return this.metodos.postQuery(WS_MONITOREO, body, headers);


  }


}
