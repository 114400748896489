import { Injectable } from '@angular/core';
import { VariablesEntorno } from '../../models/variablesEntorno';
import { interval, Subscription, timer } from 'rxjs';
import { UsuarioService } from '../usuario/usuario.service';
import { first, take } from 'rxjs/operators';
import { ModbusService } from '../modbus/modbus.service';
import { VariablesServices } from '../../config/variables';

@Injectable({
  providedIn: 'root'
})
export class MonitoreoService {

  private tiempo = 1000 * 60 * 60;
  /*   private tiempo = 1000 * 60; */
  private contadorTimer = 0;
  timeout: Subscription = new Subscription();
  interval: Subscription = new Subscription();
  constructor(
    private env: VariablesServices,
    private user: UsuarioService,
    private modbuss: ModbusService
  ) {
    if (localStorage.getItem('contadorSession')) {
      this.contadorTimer = +localStorage.getItem('contadorSession');
    }
    this.guardarContador(this.contadorTimer);
  }


  recursiveSession(maxExpire: number, contadorMemoria: number) {
    console.log('enviando monitoreo  en : ' + (maxExpire - contadorMemoria) + 'ms');
    this.timeout = timer(maxExpire - contadorMemoria).subscribe(() => {
      this.monitoreo();
    });
  }
  iniciarMonitoreo() {
    this.recursiveSession(this.tiempo, this.contadorTimer);
  }

  monitoreo() {
    this.interval.unsubscribe();
    this.timeout.unsubscribe();
    localStorage.setItem('contadorSession', 0 + '');
    this.guardarContador(0);
    this.recursiveSession(this.tiempo, 0);
    const codigo = 0;
    if (this.user.estaLogueado()) {
      this.modbuss.modbusLeerMonitoreo(this.env.MODBUS_ADDRESS_ESTADO) // lee ele atdo del mobuss
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res.payload.registers[0] === 0) { // si es cero es porque hubo un error
            this.modbuss.modbusLeerMonitoreo(this.env.MODBUS_ADDRESS_ERROR)
              .pipe(take(1))
              .subscribe((mod: any) => {
                const resp = mod.payload.registers[0]; // se envia el error al ws 
                console.log({ error_mod: resp });
                this.user.monitorear({ codigo: resp })
                  .pipe(take(1))
                  .subscribe(() => {
                    console.log('error del mnodbus')
                  });
              });
          } else {
            console.log('todo bien');
            // si no es cero , no hay error del modbuss
            this.user.monitorear({ codigo }) // se envia que todo esta bien 
              .pipe(take(1))
              .subscribe(() => { });
          }
        });
    }


  }


  guardarContador(contador: number) {
    this.interval = interval(1000).
      subscribe((r) => {
        const time = contador + (r + 1) * 1000
        localStorage.setItem('contadorSession', time + '');

      });
  }
}
