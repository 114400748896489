import { Injectable } from '@angular/core';
import { SocketsService } from './sockets.service';
import { ESCUCHAR_NUEVO_TICKET, EMITIR_ESTADO_RITA, ESCUCHAR_PREPARAR_JUGO } from '../../config/eventos-socket';

@Injectable({
  providedIn: 'root'
})
export class SocketPedidoService {

  public idPedidoEjecucion = 0;
  public ritaOcupada = false;
  constructor(private socket: SocketsService) { }
  emitirEstadoRita(idCliente: number, tipoMensaje: number, mensaje: string, callBack: (res: any) => void) {
    this.socket.emitir(EMITIR_ESTADO_RITA, { idCliente, tipoMensaje, mensaje }, callBack);
  }

}
