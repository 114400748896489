import { Component, OnInit, OnDestroy } from '@angular/core';
import { DesinfeccionService } from '../services/desindeccion/desinfeccion.service';
import { DevService } from '../services/dev/dev.service';
import { SocketsService } from '../services/web-sockets/sockets.service';
import { ComunicacionModbussService } from '../services/modbus/comunicacion-modbuss.service';

@Component({
  selector: 'app-rita',
  templateUrl: './rita.component.html',
  styleUrls: ['./rita.component.css']
})
export class RitaComponent implements OnInit, OnDestroy {

  constructor(
    private dev: DevService,
    private socket: SocketsService,
    private des: DesinfeccionService

  ) {
  }

  ngOnInit(): void {
    this.socket.verificarConexion();
    this.socket.configurarUsuario();
    const item = this.dev.obtenerConfig();

    if (item.modbuss) {
      console.log('Inicoa pag rita con modbuss');
      this.des.iniciarDesinfeccion();
    } else {
      console.log('Inicoa pag rita sin modbuss');
    }
  }
  ngOnDestroy() {
    const item = this.dev.obtenerConfig();
    console.log(' se fui la pag rita');
    if (item.modbuss) {
      this.des.deshabilitarDesinfeccion();
    }
  }
}
