import { Injectable } from '@angular/core';
import { MetodosService } from '../metodos/metodos.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { MB_REGISTRAR, MB_LEER, MB_OPEN } from '../../config/rutas';
import { Mod } from '../../models/mod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModbusService {

  constructor(private metodos: MetodosService) { }

  modbusRegistrarFruta(body: Mod) {
    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });

    const json = { ADDRESS: body.address_fruta, VALUE: body.value_fruta };
    return this.metodos.modbus(MB_REGISTRAR, json, headers);

  }


  validarSeleccionTopping(body: Mod): Observable<any> {
    return new Observable(observer => {

      if (body.value_topping === 0) {
        let res = { estado: true };
        observer.next(res);
        observer.complete();

      }
      else {
        this.modbusRegistrarTopping(body).subscribe((res: any) => {
          observer.next(res);
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });
      }


    });

  }
  modbusRegistrarTopping(body: Mod) {

    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });


    const json = { ADDRESS: body.address_topping, VALUE: body.value_topping };

    return this.metodos.modbus(MB_REGISTRAR, json, headers);

  }
  modbusRegistrarEstado(adress: string, value: string) {


    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });

    const json = { ADDRESS: adress, VALUE: value };

    return this.metodos.modbus(MB_REGISTRAR, json, headers);

  }
  modbusLeer(id: Number) {

    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });



    const json = { ADDRESS: id };
    return this.metodos.modbus(MB_LEER, json, headers);

  }
  modbusLeerMonitoreo(id: number) {

    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });



    const json = { ADDRESS: id };
    return this.metodos.modbus(MB_LEER, json, headers);

  }
  modbusLeerEstadoDesfineccion(id: number) {

    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });



    const json = { ADDRESS: id };
    return this.metodos.modbus(MB_LEER, json, headers);

  }

  modbusRegistrarTipoDesinfeccion(addres: any, value: any) {

    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });


    const json = { ADDRESS: addres, VALUE: value };

    return this.metodos.modbus(MB_REGISTRAR, json, headers);

  }

  mdbussOPen(evento: string) {


    const headers = new HttpHeaders({
      'Content-Type': `application/json`
    });

    const json = { evento: evento };

    return this.metodos.modbus(MB_OPEN, json, headers);

  }
}
