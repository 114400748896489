import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { MetodosService } from '../../services/metodos/metodos.service';
import { PedidoService } from '../../services/pedido/pedido.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  titulo: string;
  clases: string;
  flag: boolean;
  tipoRita = 0;
  constructor(
    private router: Router,
    private service: UsuarioService,
    public ms: MetodosService,
    public pedido: PedidoService,
    public location: Location
  ) {
    this.getDataRoute().subscribe(event => {
      this.titulo = event.titulo;
      this.clases = event.clase;
    });

  }
  atras() {
    this.location.back();
  }
  ngOnInit() {
    this.ms.flagBotonMenu;

    const sessionRita = JSON.parse(localStorage.getItem('rita'));
    this.tipoRita = sessionRita.tipo_rita_id;

  }
  getDataRoute() {
    return this.router.events.pipe(
      filter(evento => evento instanceof ActivationEnd),
      filter((evento: ActivationEnd) => evento.snapshot.firstChild === null),
      map((evento: ActivationEnd) => evento.snapshot.data)
    );
  }

  logout() {
    this.service.logout();
  }

}


