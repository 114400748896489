import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class VariablesServices {


    public DURATIONSNACK = 2000; // en milisegundos
    public API_KEY = 'AIzaSyDNOu2JQ001PxZY-GVwFvVou0_6h_Sj-14'; // key del goople maps
    public NUMERO_MAX_PETICIONES = 10;
    public INTERVALO_PETICIONES = 8;  // s egundos
    public MOD_NUMERO_MAX_PETICIONES = 2;
    public MOD_INTERVALO_PETICIONES = 5;  // segundos
    public TIEMPO_PANTALLA_CARGA = 60;  // segundos
    public TOKEN_DIAS = 2; // cada cuántos dias  debe renovar el token 
    public CELULAR_SOPORTE_TECNICO = '0000000';
    public MODBUS_ADDRESS_ERROR = 36;
    public MODBUS_ADDRESS_ESTADO = 34;
    constructor() {

    }
}

