
export class Usuario {

    constructor(
        public usuario_id?:string,
        public name?: string,
        public password?: string
    
 
        
    ) { }

}