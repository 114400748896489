export const WS_UBIGEO = 'perfil/listar_ciudades';
export const WS_RESET_PASSWORD = 'reset_password';
export const WS_ACCION = 'perfil/listar_que_hacer';
export const WS_USUARIO = 'perfil/usuario';
export const WS_GET_FRUTAS = 'rita/frutas';
export const WS_SELEC_FRUTA = 'pedido/fruta/seleccionar';
export const WS_GET_TOPPINGS = 'rita/toppings';
export const WS_SELEC_TOPPING = 'pedido/topping/seleccionar';
export const WS_PEDIDO_RESUMEN = 'pedido/resumen';
export const WS_SELEC_RITA = 'rita/seleccionar';
export const WS_LIMPIAR_PEDIDO = 'pedido/limpiar';
export const WS_PAGAR = 'pagar/datos_usuario';
export const WS_CONFIRMAR_PAGO = 'pagar/confirmar';
export const WS_REGISTRO_PEDIDO = 'pagar/registrar_compra';
export const WS_CONFIRMAR_PAGO_RITA = 'pagar/confirmar_pago_rita';
export const WS_CONFIRMAR_JUGO_PREAPARADO = 'pedido/confirmar_jugo_preparado';


export const WS_VALIDAR_PEDIDO_APP = 'pedido/validar_pedido_app';
export const WS_REGISTRAR_ESTADO_PEDIDO = 'pedido/registrar_estado_pedido_app';

// login
export const WS_LOGIN_RITA = 'auth/login_rita';
export const WS_LOGOUT = 'auth/logout';
export const WS_REFRESH = 'auth/refresh_rita';
export const WS_AUTH_ME = 'auth/me';
export const WS_VARIABLE_ENTORNO = 'auth/variables_entorno';
// modbus
export const MB_REGISTRAR = 'fc6';
export const MB_LEER = 'fc3';
export const MB_OPEN = 'open';
// java
export const COMPRA_NORMAL = 'compra_normal';

export const WS_MSJ_AVERIA = 'utils/sos_rita'
export const WS_LOG_REGISTRAR = 'registrar_log';

export const ELIMINAR_USUARIO_SOCKET = 'eliminar_usuario_socket_por_WS';
export const WS_MONITOREO = 'rita/monitoreo';
export const WS_ENVIAR_VOUCHER = 'rita/enviar_voucher';
export const WS_PEDIDO_DETALLE = 'pedido/detalle';

export const WS_DESINFECCION_INICIO = 'desinfeccion/registrarDesinfeccionInicio';
export const WS_DESINFECCION_FIN = 'desinfeccion/registrarDesinfeccionFin';
export const WS_VALIDAR_CUPON = 'cupon/validar_cupon_vencimiento';
export const WS_REGISTRAR_CUPON_PEDIDO = 'cupon/registrar_cupon_pedido';
export const WS_LISTAR_JUGO_FUNCIONAL = 'rita/jugos_funcionales';
export const WS_SELECCIONAR_JUGO_FUNCIONAL = 'pedido/tipo_jugo/seleccionar';
export const WS_RESUMEN_FUNCIONAL = 'pedido/resumen_funcional';
export const WS_RESUMEN_PAGO_FUNCIONAL = 'pedido/detalle_funcional';




export const WS_LISTAR_PUBLICIDAD = 'pub/listar_pub';
export const WS_AUTENTICAR_RITA_PUBLICIDAD = 'validarLoginRita';