import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginGuard } from './guards/login.guard';
import { MetodosService } from './metodos/metodos.service';
import { UsuarioService } from './usuario/usuario.service';
import { HttpClientModule } from '@angular/common/http';
import { ModbusService } from './modbus/modbus.service';
import { PosService } from './pos/pos.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [LoginGuard, MetodosService, UsuarioService, ModbusService, PosService]
})
export class ServiceModule { }

