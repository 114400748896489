import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { MetodosService } from './services/metodos/metodos.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MonitoreoService } from './services/monitoreo/monitoreo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'disfruta';
  isConnected = true;
  socketPedido$ = new Subscription();
  status = 'ONLINE';
  subsSocket: Subscription;
  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private ws: MetodosService,
    private moni: MonitoreoService,




  ) {

    this.connectionService.monitor().subscribe((isConnected: boolean) => {
      this.isConnected = isConnected;
      if (this.isConnected) {

        if (!this.ws.flagErrorModbus) {
          this.status = 'ONLINE';
          this.router.navigate(['/']);
        }
      } else {
        this.status = 'OFFLINE'
        this.router.navigate(['/fuera_servicio']);
      }
    });

  }
  ngOnInit() {
    this.moni.iniciarMonitoreo();

  }

  ngOnDestroy() {


  }


}
