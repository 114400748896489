import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { OnboardComponent } from './onboard/onboard.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// router van en los imports
import { APP_ROUTES } from './route';
import { ServiceModule } from './services/service.module';
import { OlvidoClaveComponent } from './login/olvido-clave.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ConfirmacionComponent } from './login/confirmacion.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenInterceptor } from './helpers/loading.interceptor';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { PantallaComponent } from './rita/pantalla/pantalla.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/es-PE';
import { FueraServicioComponent } from './fueraServicio/fuera-servicio.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { RitaComponent } from './rita/rita.component';
const config: SocketIoConfig = { url: environment.URL_SOCKET, options: {} };
// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'PE');
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OnboardComponent,
    RitaComponent,
    OlvidoClaveComponent,
    ConfirmacionComponent,
    LoadingScreenComponent,
    FueraServicioComponent
  ],
  imports: [
    SocketIoModule.forRoot(config),
    BrowserModule,
    SharedModule,
    APP_ROUTES,
    ServiceModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule


  ],

  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingScreenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
